
.rdrDateRangePickerWrapper{
    box-shadow: none;
    width: fit-content;
    /* border-radius:16px; */
    overflow: hidden;
    /* margin-left: 200px; */
}

/**

Side

**/

.rdrDefinedRangesWrapper span {
}
.rdrDefinedRangesWrapper {
    background: #ecf0f5;
    padding:16px;
    width:180px;
    font-family: 'Roboto';
    border-right: 0px;
}
.rdrStaticRange{
    border:0;
    border-radius:4px;
    background:transparent
}
.rdrStaticRange .rdrStaticRangeLabel{
    padding:0;
    height:40px;
    line-height: 40px;
    padding:0 16px;
    transition: 250ms;
    color:#31415f;

    font-size: 14px;
}
.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel{
    background:transparent;
    color:#0D61FF;
    font-weight: 500;
    transition: 250ms;
}
.rdrStaticRange.rdrStaticRangeSelected{
    background: rgba(65,128,246,.15) !important;
}
.rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel{
    color:#0D61FF;
    font-weight: 500;

}
.rdrInputRanges{
    display: none;
}

/** Main container **/
.rdrDateDisplayWrapper{
    display: none;
}
.rdrMonthAndYearWrapper{
    padding: 24px;
    height:unset;
    padding-bottom: 16px;
}

.rdrNextPrevButton{
    margin:unset;
    width:32px;
    height:32px;
    background:#ecf0f5;
    transition: 250ms;
}
.rdrNextButton i{
    margin: 0 0 0 11px;
    border-color: transparent transparent transparent #31415f;
}
.rdrPprevButton i {
    border-color: transparent #31415f transparent transparent;
}
.rdrNextPrevButton:hover{
    background: #ced7e1;
    transition: 250ms;
}

.rdrMonthPicker, .rdrYearPicker {
    margin:0 8px;
}
.rdrMonthAndYearPickers select {
    text-align: left;
    padding:10px 24px 10px 16px;
    transition: 250ms;
    color:#31415f;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size:14px
}
.rdrMonthAndYearPickers select:hover {
    background:#ecf0f5;
    transition: 250ms;
}

.rdrMonth{
    padding-top:0px;
    padding-left:24px;
    padding-right:24px;
}

.rdrDayNumber span{
    color:#31415f;
    font-weight: 500;
    font-size:13px;
    font-family: 'Roboto', sans-serif;
}
.rdrDayPassive .rdrDayNumber span,.rdrWeekDay {
    color:rgba(46,65,95,.55);
    font-weight: 400;
    font-size:13px;
}
.rdrStartEdge{
    color:#0D61FF;
}
.rdrDayEndOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrInRange, .rdrInRange, .rdrSelected{
    background: rgba(65,128,246,.15);
    
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
    color:#fff;
    font-weight: 700;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color:#0D61FF;
    font-weight: 500;
}

.rdrDayInPreview,.rdrDayStartPreview,.rdrDayEndPreview{
    border-color: orange;
    border:none;
}
.rdrDayInPreview,.rdrDayStartPreview,.rdrDayEndPreview{
    background: rgba(65,128,246,.15);
    color:#0D61FF;

}
.rdrDayDisabled{
    background:#ecf0f5;
}
.rdrDayDisabled .rdrDayNumber span {
    color:rgba(46,65,95,.55);
}


/**Dark**/
.rdrDateRangePickerWrapper.dark .rdrDefinedRangesWrapper{
    background-color: #181820;
}

.rdrDateRangePickerWrapper.dark .rdrStaticRange .rdrStaticRangeLabel {
    color:#fff;
}

.rdrDateRangePickerWrapper.dark .rdrStaticRange:hover .rdrStaticRangeLabel,.rdrDateRangePickerWrapper.dark .rdrStaticRange:focus .rdrStaticRangeLabel {
    color:#8DB5FF;
}

.rdrDateRangePickerWrapper.dark .rdrStaticRange.rdrStaticRangeSelected{
    background: rgba(141,181,255,.15) !important;
}
.rdrDateRangePickerWrapper.dark .rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel{
    color:#8DB5FF;

}

/* 2A2A37 */
.rdrDateRangePickerWrapper.dark .rdrCalendarWrapper {
    background-color: #2A2A37;
}

.rdrDateRangePickerWrapper.dark .rdrNextPrevButton{
    background:#353545;
}

.rdrDateRangePickerWrapper.dark .rdrPprevButton i {
    border-color: transparent #fff transparent transparent;
}

.rdrDateRangePickerWrapper.dark .rdrNextButton i {
    border-color: transparent transparent transparent #fff;
}

.rdrDateRangePickerWrapper.dark .rdrNextPrevButton:hover{
    background-color: #404054;
}

.rdrDateRangePickerWrapper.dark .rdrMonthAndYearPickers select{
    color:#fff;
}

.rdrDateRangePickerWrapper.dark .rdrMonthAndYearPickers select:hover{
    background-color: #353545;
} 

.rdrDateRangePickerWrapper.dark .rdrDayNumber span {
    color:#fff;
}
.rdrDateRangePickerWrapper.dark .rdrDayPassive .rdrDayNumber span,.rdrDateRangePickerWrapper.dark .rdrWeekDay {
    color:#88869D;
}
.rdrDateRangePickerWrapper.dark .rdrDayDisabled{
    background-color: #353545;
    opacity: .5;
}
.rdrDateRangePickerWrapper.dark .rdrDayDisabled .rdrDayNumber span{
    color:#88869D;
}

.rdrDateRangePickerWrapper.dark .rdrStartEdge, .rdrDateRangePickerWrapper.dark.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDateRangePickerWrapper.dark .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color:#8DB5FF;
}


.rdrDateRangePickerWrapper.dark .rdrDayEndOfWeek .rdrInRange,.rdrDateRangePickerWrapper.dark .rdrDayStartOfWeek .rdrInRange, .rdrInRange,.rdrDateRangePickerWrapper.dark .rdrSelected{
    background: rgba(141,181,255,.15);
    
}

.rdrDateRangePickerWrapper.dark .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDateRangePickerWrapper.dark .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span{
    /* color:rgba(0,0,0,.75); */
    /* font-weight: 700; */
}

.rdrDateRangePickerWrapper.dark .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDateRangePickerWrapper.dark .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color:#8DB5FF;
    font-weight: 500;
}

.rdrDateRangePickerWrapper.dark .rdrDayInPreview,.rdrDayStartPreview,.rdrDateRangePickerWrapper.dark .rdrDayEndPreview{
    background: rgba(141,181,255,.15);
    color:#8DB5FF;

}


/**Mobile**/
@media screen and (max-width:48em) {
    .rdrDateRangePickerWrapper{
        width:100%;
    }
    .rdrDateRangePickerWrapper.dark{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .rdrDefinedRangesWrapper{
        display: none;
    }
    .rdrCalendarWrapper {
        width:100%;
    }
    .rdrMonth{
        width:100%;
    }
}