@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  box-sizing: border-box !important;
  /* transition:250ms; */
}
body > iframe {
  /* display:none !important; */
}

html, body, #root, .app, .content {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.content{
  width: calc(100% - 342px);
  margin-left:342px;
  transition: 500ms;
}

.app .menu{
  left:0;
  transition: 500ms;
  animation: none !important;
}
.app .menu.collapsed{
  left:-342px;
  transition: 500ms;
  animation: none !important;
}

.app .content{
  display:flex;
}

.app .content .topbar {
  position: fixed;
  width:calc(100% - 342px);
  transition: width 500ms, background 250ms;
  /* transition: background 250ms; */
  z-index:50
}

.app .menu.collapsed ~ .content .topbar {
  width:100%;
  transition: width 500ms, background 250ms;
}

.app .menu.collapsed ~ .content{
  width:100%;
  margin-left:0;
  transition: 500ms;
}

.app .content.hide-side{
  margin-left:0;
  width:100%;
}

.search-modal{
  z-index: 1199 !important;
}

@media screen and (max-width: 85.5em){

  .app .menu {
    left:-342px;
    transition: 500ms;
    animation: none !important;
    z-index: 1001;
  }
  /* Collapsed = in on smaller screens */
  .app .menu.collapsed {
    left:0px;
    z-index: 1001;
    transition: 500ms;
    
  }


    .app .content .topbar {
      width:100%;
    }
  .app .menu.collapsed ~ .content,  .app .menu ~ .content{
    width:100%;
    margin-left:0;
    transition: 500ms;
  }
}

@media screen and (max-width: 47.9375em) {
  .app .menu{
    width:100% !important;
    left:-100%;
  }
}
/* ::-webkit-scrollbar {
  width: 10px;
} */

/** Track */
/* ::-webkit-scrollbar-track {
  background-color: #e0e0e0;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/**popover**/


@keyframes dash  {
  to  {
    stroke-dashoffset: -1000;
  }
}