@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

.truck-container {
	position: relative;
  margin-top: 8px;
}


.truck {
	width: 48px;
	overflow: visible;
}

#Layer_2-2 {
	animation: carMove infinite 0.4s;
}

#Layer_3 {
	transform-origin: 23px 78px;
		animation: wheelRotate infinite 0.4s linear;
}

#Layer_4 {
	transform-origin: 90px 78px;
	animation: wheelRotate infinite 0.4s linear;
}

.wind{
  position: absolute;
  z-index: 1;
  top: 0;
  left: -20px;
  height: 64px;
}

  .p{
    position: absolute;
    background: #31415f;
    border-radius: 10px;    
    height: 4px;
  }
  .p1{
    width: 16px;
    position: absolute;
    right: 0px;
    top: 4px;
    animation: wind 1s -1s linear infinite;
  }
  .p2{
   width: 16px;
   right: 14px;
   top: 16px; 
    animation: wind 1s -2s linear infinite;
  }
  .p3{
   width: 16px;
   right: 00px;
   top: 28px; 
    animation: wind 1s -1s linear infinite;
  }

@keyframes wind{
  0%{
    transform: translateX(100%);
    opacity: 0;
  }
20%{
    transform: translateX(25%);
    opacity: 1;
  }
  100%{
    transform: translateX(-200%);
    opacity: 0;
  }
}

@keyframes carMove {
  0%{
    transform: translateY(0px);
  }
  50%{
   transform: translateY(-1px); 
  }
  100%{
    transform: translateY(0px);
  }
}

@keyframes wheelRotate {
  0%{
    transform: rotate(0deg) skew(5deg);
  }
  100%{
    transform: rotate(360deg) skew(5deg);
  }
}